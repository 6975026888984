<div *ngIf="isDataAvailable">
    <div class="row">
      <h1>{{client.prenom}} {{client.nom}}</h1>
        <div class="card col">
            <div class="info-principales card-body">
                <div class="card-title row">
                    <h1 class="col-sm-8">Information principales</h1>
                    <button class="col-sm-4" mat-icon-button color="primary" aria-label="Modifier" (click)="editPrincipalInfo()">
                                    <mat-icon>create</mat-icon>
                                  </button>
                </div>
                <div class="row card-text">
                    <div class="col-sm-6" [innerText]="client.adresse">
                    </div>
                    <div class="col-sm-6">
                        <p>Sexe: <span>{{sexes[client.sexe]}}</span></p>
                        <p>Langue: <span>{{langues[client.langue] ? langues[client.langue]: "-"}}</span></p>
                        <p>Date de naissance: <span>{{client.date == "" ? 'Aucune Information' : client.date}}</span></p>
                        <p>N° AVS: <span>{{client.navs ? client.navs : "-"}}</span></p>
                        <p>Etat civil: <span>{{etatCivils[client.etatc]}}</span></p>
                      <p>Téléphone domicile: <span><a href="tel:{{client.teldom}}">{{client.teldom ? client.teldom : "-"}}</a></span></p>
                        <p>Téléphone professionnel: <span><a href="tel:{{client.telpro}}">{{client.telpro ? client.telpro : "-"}}</a></span></p>
                        <p>Téléphone portable: <span><a href="tel:{{client.telnat}}">{{client.telnat ? client.telnat : "-"}}</a></span></p>
                        <p>E-mail: <span>
                          <a href="mailto:{{client.email}}"> {{client.email}}</a></span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card col">
            <div class="info-principales card-body">
                <div class="card-title row">
                    <h1 class="col-auto">Autres informations</h1>
                    <button class="col-auto" mat-icon-button color="primary" aria-label="Modifier" (click)="editAutreInfo(client)">
                                        <mat-icon>create</mat-icon>
                                      </button>
                </div>
                <div class="card-text">

                    <!--p>Signalé par l'employeur: <span>{{booleans[client.signale] ? booleans[client.signale]: "NON"}}</span></p>
                    <p>Signalé par les collègues: <span>{{booleans[client.signalec] ? booleans[client.signalec] : "NON"}}</span></p>
                    <p>Signalé par d'autres: <span>{{client.signaler ? client.signaler : "NON"}}</span></p-->
                    <p>Collaborateur/trice: <span> {{client.assistante.nom}}</span></p>
                    <p>Etat du dossier: <span [ngClass]="{'green': actif[client.etatDossier] === 'ACTIF', 'red':actif[client.etatDossier] != 'ACTIF' }">{{actif[client.etatDossier]}}</span></p>
                    <p>Déclaration d'impôt: <span>{{client.declaration ? booleans[client.declaration] : 'NON'}}</span></p>
                    <p>Entreprise: <span>{{client.entreprise.nom}}</span></p>
                    <p>Personne de référence: <span>{{client.employeur.nom}}</span></p>
                    <p>Motifs de consultation: <span>{{motifs.toString()}}</span></p>
                  <p>Familial / Autres personnes: <span>{{client.reseauf ? client.reseauf : "Aucune information"}}</span></p>

                </div>
            </div>
        </div>

        <div>
            <mat-tab-group class="tab-group">
                <mat-tab class="tab-body" label="Journal de suivi">
                    <button *ngIf="!addJournal" mat-flat-button class="createButton" color="primary" (click)="createJournal()">Créer une nouvelle entrée</button>
                    <div *ngIf="addJournal">
                      <div class="row">
                        <div class="col-auto">
                          <div class="input-group">
                            <input class="form-control" placeholder="dd.MM.yyyy"
                                   id="dateSuivi" name="dateSuivi" [(ngModel)]="dateSuivi" ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary bi bi-calendar" (click)="d.toggle()" type="button">
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-auto">
                          <textarea class="text-area form-control" placeholder="Description" [(ngModel)]="newJournal.description"></textarea>
                        </div>
                        <div class="col-auto">
                          <select class="form-select" [(ngModel)]="newJournal.type" name="typeSuivi" id="typeSuivi">
                            <option [value]="typesSuivi['Téléphone']">{{typesSuivi[typesSuivi['Téléphone']]}}</option>
                            <option [value]="typesSuivi['Email']">{{typesSuivi[typesSuivi['Email']]}}</option>
                            <option [value]="typesSuivi['Entretien']">{{typesSuivi[typesSuivi['Entretien']]}}</option>
                            <option [value]="typesSuivi['Entretien sur site']">{{typesSuivi[typesSuivi['Entretien sur site']]}}</option>
                            <option [value]="typesSuivi['Courrier']">{{typesSuivi[typesSuivi['Courrier']]}}</option>
                            <option [value]="typesSuivi['Autre']">{{typesSuivi[typesSuivi['Autre']]}}</option>
                          </select>
                        </div>
                      </div>
                      <button mat-flat-button color="primary" class="createButton" (click)="saveJournal()" >Sauvegarder</button>

                    </div>
                  <mat-card *ngFor="let message of client.journalList.slice().reverse()">
                        <mat-card-title> {{message.date}} - {{typesSuivi[message.type]}}</mat-card-title>
                        <mat-card-content [innerText]="message.description">
                          <!--p>
                            {{message.description}}
                          </p-->
                        </mat-card-content>
                        <mat-card-actions align="end">
                            <button mat-icon-button color="primary" aria-label="Modifier" (click)="editJournal(message)">
                                    <mat-icon>create</mat-icon>
                                  </button>
                            <button mat-icon-button color="primary" aria-label="Supprimer" (click)="deleteJournal(message)">
                                    <mat-icon>delete</mat-icon>
                                  </button>
                        </mat-card-actions>
                    </mat-card>
                </mat-tab>
                <mat-tab label="Fichiers joints">
                    <div>
                      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                        <!-- This is the tree node template for leaf nodes -->
                        <mat-tree-node class="mat-tree-clickable" *matTreeNodeDef="let node" matTreeNodePadding (click)="openFile(node)">
                          <!-- use a disabled button to provide padding for tree leaf -->
                          <button mat-icon-button disabled></button>
                          {{node.name}}
                        </mat-tree-node>
                        <!-- This is the tree node template for expandable nodes -->
                        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                          <button mat-icon-button matTreeNodeToggle
                                  [attr.aria-label]="'Toggle ' + node.name">
                            <mat-icon class="mat-icon-rtl-mirror">
                              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                          </button>
                          {{node.name}}
                        </mat-tree-node>
                      </mat-tree>
                    </div>

                </mat-tab>
                <mat-tab label="Mariage">
                    <button *ngIf="!editionMariage" mat-flat-button class="createButton" color="primary" (click)="addMariage(client)">Créer une nouvelle entrée</button>
                  <div *ngIf="editionMariage">
                    <div class="row row-edition-mariage">
                      <div class="col-auto">
                          <input type="text" class="form-control" id="inputnom" [(ngModel)]="newMariage.nom" name="nom" placeholder="Prénom Nom du/de la conjoint/e">
                      </div>
                      <div class="col-auto">
                        <div class="input-group">
                          <input class="form-control" placeholder="Date du mariage"
                                 id="dateDebutMariage" name="dateDebutMariage" [(ngModel)]="dateDebutMariage" ngbDatepicker #d="ngbDatepicker">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary bi bi-calendar" (click)="d.toggle()" type="button">
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="input-group">
                          <input class="form-control" placeholder="Date du divorce"
                                 id="dateFinMariage" name="dateFinMariage" [(ngModel)]="dateFinMariage" ngbDatepicker #dF="ngbDatepicker">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary bi bi-calendar" (click)="dF.toggle()" type="button">
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto">
                        <button mat-flat-button color="primary" class="createButton" (click)="saveMariage()" >Sauvegarder</button>
                      </div>

                    </div>


                  </div>
                    <mat-card *ngFor="let message of client.mariagesList">
                        <mat-card-title>{{message.nom}}</mat-card-title>
                        <mat-card-subtitle>
                            <p>Date du mariage: <span>{{message.dateDebut}}</span></p>
                            <p>Divorce: <span>{{message.etat ==1 ? divorceEnum[message.etat] :  (message.dateFin) }}</span></p>
                        </mat-card-subtitle>
                        <mat-card-actions align="end">
                            <button mat-icon-button color="primary" aria-label="Modifier" (click)="editMariage(message)">
                                    <mat-icon>create</mat-icon>
                                  </button>
                            <button mat-icon-button color="primary" aria-label="Supprimer" (click)="deleteMariages(message)">
                                    <mat-icon>delete</mat-icon>
                                  </button>
                        </mat-card-actions>
                    </mat-card>
                </mat-tab>
                <mat-tab label="Enfants">
                    <button mat-flat-button class="createButton" color="primary" (click)="addEnfant(client)">Créer une nouvelle entrée</button>
                  <div *ngIf="editionEnfant">
                    <div class="row row-edition-mariage">
                      <div class="col-auto">
                        <input type="text" class="form-control" id="inputnomE" [(ngModel)]="newEnfant.nom" name="nom" placeholder="Prénom Nom de l'enfant">
                      </div>
                      <div class="col-auto">
                        <textarea class="text-area form-control" placeholder="Adresse" [(ngModel)]="newEnfant.adresse"></textarea>
                      </div>
                      <div class="col-auto">
                        <div class="input-group">
                          <input class="form-control" placeholder="Date de naissance"
                                 id="dateNaissanceE" name="dateNaissanceE" [(ngModel)]="dateNaissanceE" ngbDatepicker #d="ngbDatepicker">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary bi bi-calendar" (click)="d.toggle()" type="button">
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto">
                        <button mat-flat-button color="primary" class="createButton" (click)="saveEnfant()" >Sauvegarder</button>
                      </div>
                    </div>
                    </div>
                    <ul class="list-group list-group-horizontal">
                        <li class="list-group-item" *ngFor="let enfant of client.enfantsList">
                            <mat-card>
                                <mat-card-title>{{enfant.nom}}</mat-card-title>
                                <mat-card-subtitle>
                                    <p>{{enfant.adresse}}</p>
                                    <p>Né-e le {{ enfant.date }}</p>
                                </mat-card-subtitle>
                                <mat-card-actions align="end">
                                    <button mat-icon-button color="primary" aria-label="Modifier" (click)="editEnfant(enfant)">
                                        <mat-icon>create</mat-icon>
                                      </button>
                                    <button mat-icon-button color="primary" aria-label="Supprimer" (click)="deleteEnfant(enfant)">
                                    <mat-icon>delete</mat-icon>
                                    </button>
                                </mat-card-actions>
                            </mat-card>
                        </li>
                    </ul>
                </mat-tab>
                <!--mat-tab label="Reseaux">
                    <mat-card>
                        <mat-card-title>Familial / Autres personnes:</mat-card-title>
                        <mat-card-subtitle>
                            {{client.reseau ? client.reseau : "Aucune information"}}
                        </mat-card-subtitle>
                        <mat-card-actions align="end">
                            <button mat-icon-button color="primary" aria-label="Modifier" (click)="editReseau(client)">
                                <mat-icon>create</mat-icon>
                              </button>
                        </mat-card-actions>
                    </mat-card>

                </mat-tab-->
                <mat-tab label="Historique">
                    <mat-table [dataSource]="dataSourceHistorique" matSort>

                        <ng-container matColumnDef="date">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.date}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Action </mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.action}} </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumnsHistorique"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsHistorique;"></mat-row>

                    </mat-table>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
