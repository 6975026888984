import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './client/home/home.component';
import { FailedComponent } from './failed/failed.component';
import { ClientDetailComponent } from './client/client-detail/client-detail.component';
import { ClientEditComponent } from './client/client-edit/client-edit.component';
import {FormsModule} from '@angular/forms';
import {ClientNewComponent} from './client-new/client-new.component';
import { StatistiqueComponent } from './statistique/statistique.component';

const routes: Routes = [

  {
    path: 'entreprises',
    component: HomeComponent,
    canActivate: [MsalGuard]
  },
   {
    path: 'stats',
    component: StatistiqueComponent,
    canActivate: [MsalGuard]
  },

  {
    path: 'login-failed',
    component: FailedComponent
  }, {
    path: 'client/:id',
    component: ClientDetailComponent,
    canActivate: [MsalGuard]
  }, {
    path: 'client/:id/edit',
    component: ClientEditComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'client-new',
    component: ClientNewComponent,
    canActivate: [MsalGuard]
  },
  {
    path: '',
    redirectTo: 'clients', pathMatch: 'full'
  },
  {
    path: 'clients',
    component: HomeComponent,
    canActivate: [MsalGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule, FormsModule]
})
export class AppRoutingModule { }
