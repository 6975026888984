import {Component, Input, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Clients} from '../../models/clients.model';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {ClientService} from '../../services/client.service';
import {Sexe} from '../../enum/sexe.enum';
import {ClientLightModel} from '../../models/client-light.model';
import {Location} from '@angular/common';
import {Assistante} from '../../models/users.model';
import {Entreprises} from '../../models/entreprises.model';
import {Employeurs} from '../../models/employeurs.model';
import {ActifNonActif} from '../../enum/actif.enum';
import {BooleanField} from '../../enum/booleanField.enum';
import {Historique} from '../../models/historique.model';
import moment from 'moment';
import {NgbActiveModal, NgbCalendar, NgbDate, NgbDateStruct, NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {EtatCivil} from '../../enum/etatcivil.enum';
import {NgbDateFRParserFormatter} from '../../utils/ngb-date-fr-parser-formateur';
import {HttpHeaders} from '@angular/common/http';
import {LangueEnum} from '../../enum/langue.enum';

@Component({
  selector: 'app-principal-info-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.css'],
  providers: [NgbModalConfig, NgbModal]
})

export class ClientEditComponent implements OnInit {
  client: Clients;
  // clientLight: ClientLightModel;
  sauvegardeStatus: string;
  isDataAvailable = false;
  isPrincipalInfo: string;
  assistantesList: Assistante [] = [];
  entreprises: Entreprises[] = [];
  employeurs: Employeurs[] = [];
  sexes = Sexe;
  langues = LangueEnum;
  etatcivils = EtatCivil;
  actifs = ActifNonActif;
  booleanField = BooleanField;
  clientJson: Clients;
  selectedEntreprise: Entreprises;
  dateHistory: NgbDate;
  birthdayDate: NgbDateStruct;
  beforeUpdateEtat: boolean;
  updated: boolean;
  needHistorique = false;
  oldCollaborateur: string;
  dateFormatter: NgbDateFRParserFormatter;
  historique;
  selectedAssitante: Assistante;
  selectedEmployeur: Employeurs;
  isLoadingResults = true;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(config: NgbModalConfig, private modalService: NgbModal, private router: Router, private actRoute: ActivatedRoute, private clientService: ClientService, private location: Location, private calendar: NgbCalendar) {
    this.dateHistory = calendar.getToday();
    this.dateFormatter = new NgbDateFRParserFormatter();
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    console.log(this.actRoute.snapshot.paramMap.get('isPrincipalInfo'));
    this.isPrincipalInfo = this.actRoute.snapshot.paramMap.get('isPrincipalInfo');

    this.loadClient(this.actRoute.snapshot.paramMap.get('id'));

    this.loadAssistantes();
    this.loadEntreprises();

    /*this.form = new FormGroup({
      title: new FormControl('', [Validators.required]),
      body: new FormControl('', Validators.required)
    });*/

  }

  // tslint:disable-next-line:typedef
  onSubmit(form: NgForm) {
    console.log('Your form data : ', form.value);
    console.log('Your client data : ', this.client);
    this.client.assistante = this.selectedAssitante;
    this.client.entreprise = this.selectedEntreprise;
    this.client.date = this.dateFormatter.format(this.birthdayDate);
    this.client.employeur = this.selectedEmployeur;
    this.clientService.update(this.client.id, this.client).subscribe(
      value => {
        console.log(value);
        if (this.needHistorique) {
          let action = '';
          if (this.client.etatDossier == 0 && Number(this.beforeUpdateEtat) != this.client.etatDossier) {
            action = 'Clôture du dossier';
          } else if (this.client.etatDossier == 1 && Number(this.beforeUpdateEtat) != this.client.etatDossier) {
            action = 'Ouverture du dossier';
          } else {
            action = 'Collaborateur: ' + this.oldCollaborateur + '-> ' + this.client.assistante.nom;
          }
          const historique = {
            date: this.dateFormatter.format(this.dateHistory),
            action,
            clientId: this.client
          };
          this.clientService.createHistorique(historique).subscribe(
            valueH => {
              console.log(valueH);
              this.location.back();

              // this.modalService.open('');
            }
          );

        } else {
          // this.modalService.open();
          this.location.back();
        }

      }
    );
  }

  // tslint:disable-next-line:typedef

  loadClient(id: string) {
    this.clientService.get(id).subscribe(
      value => {
        this.client = value;
        // tslint:disable-next-line:max-line-length
        this.birthdayDate = this.dateFormatter.parse(this.client.date);
        this.beforeUpdateEtat = Boolean(this.client.etatDossier);
        this.oldCollaborateur = this.client.assistante.nom;
        this.selectedAssitante = this.client.assistante;
        this.selectedEntreprise = this.client.entreprise;
        this.selectedEmployeur = this.client.employeur;

        this.isLoadingResults = false;
        console.log(this.client);
        console.log(this.dateHistory);


        // tslint:disable-next-line:max-line-length

      }
    );
  }

  private loadAssistantes() {
    this.clientService.getAssistances().subscribe(
      value => {
        this.assistantesList = value;
        console.log(this.assistantesList);
      }
    );

  }

  private loadEntreprises() {
    this.clientService.getEntreprises().subscribe(
      value => {
        this.entreprises = value;
        console.log(this.entreprises);
        this.employeurs = this.client.entreprise.employeursList;
        this.isDataAvailable = true;
      }
    );

  }

  onEntrepriseChange(e) {
    console.log(e);
    // tslint:disable-next-line:triple-equals
    this.client.entreprise = this.entreprises.find((entreprise: any) => entreprise.id == e);
    this.employeurs = this.selectedEntreprise.employeursList;
  }

  onEtatChange(e) {
    console.log('Compare: ' + e.target.value + ' - ' + this.beforeUpdateEtat);
    let newValue = true;
    if (e.target.value == 0) {
      newValue = false;
    }
    if (newValue != this.beforeUpdateEtat) {
      this.needHistorique = true;
    } else {
      this.needHistorique = false;
    }
  }

  onAssistanteChange(e) {
    console.log('Compare: ' + e + ' - ' + this.oldCollaborateur);
    this.client.assistante = this.assistantesList.find((assistante: any) => assistante.id == e);
    if (e.target != this.oldCollaborateur) {
      this.needHistorique = true;
    } else {
      this.needHistorique = false;
    }
  }

  onEmployeurChange(e) {
    this.client.employeur = this.employeurs.find((employeur: any) => employeur.id == e);
  }


}

