<form #it="ngForm" (ngSubmit)="onSubmit(it)" class="g-3" *ngIf="isDataAvailable">
  <div class="form-group">
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-md-4">
            <label for="inputnom" class="form-label">Nom</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="inputnom" [(ngModel)]="client.nom" name="nom">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="nomjf" class="form-label">Nom de jeune fille</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="nomjf" [(ngModel)]="client.nomjf" name="nomjf">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="adresse" class="form-label">Adresse</label>
          </div>
          <div class="col-md-8">
            <textarea class="form-control" id="adresse" style="height: 100px" name="adresse"
                      [(ngModel)]="client.adresse"></textarea>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-md-4">
            <label for="inputprenom" class="form-label">Prénom</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="inputprenom" [(ngModel)]="client.prenom" name="prenom">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="sexe" class="form-label">Sexe</label>
          </div>
          <div class="col-md-8">
            <select class="form-select" [(ngModel)]="client.sexe" name="sexe" id="sexe">
              <option [value]="sexes.Femme">{{sexes[sexes.Femme]}}</option>
              <option [value]="sexes.Homme">{{sexes[sexes.Homme]}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="langue" class="form-label">Langue</label>
          </div>
          <div class="col-md-8">
            <select class="form-select" [(ngModel)]="client.langue" name="langue" id="langue">
              <option [value]="langues.FR">{{langues[langues.FR]}}</option>
              <option [value]="langues.DE">{{langues[langues.DE]}}</option>
              <option [value]="langues.EN">{{langues[langues.EN]}}</option>
              <option [value]="langues.ES">{{langues[langues.ES]}}</option>
              <option [value]="langues.PT">{{langues[langues.PT]}}</option>
              <option [value]="langues.Autre">{{langues[langues.Autre]}}</option>
            </select>
          </div>
        </div>

      </div>

    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-md-4">
            <label for="inputteldom" class="form-label">Domicile</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="inputteldom" [(ngModel)]="client.teldom" name="teldom">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="telnat" class="form-label">Portable</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="telnat" [(ngModel)]="client.telnat" name="telnat">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="inputBirthdayDate" class="form-label">Date de naissance</label>
          </div>
          <div class="col-md-8">
            <div class="input-group">
              <input class="form-control input-date" placeholder="dd.MM.yyyy"
                     id="inputBirthdayDate" name="inputBirthdayDate" [(ngModel)]="birthdayDate" ngbDatepicker
                     #d="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}" >
              <div class="input-group-append">
                <button class="btn btn-outline-secondary bi bi-calendar" (click)="d.toggle()" type="button">
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="inputavs" class="form-label">N° AVS</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="inputavs" [(ngModel)]="client.navs" name="navs">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="entreprise" class="form-label">Entreprise</label>
          </div>
          <div class="col-md-4">
            <select id="entreprise" name="entreprise" class="form-select" [(ngModel)]="selectedEntreprise.id"
                    (ngModelChange)="onEntrepriseChange($event)">
              <option *ngFor="let entreprise of entreprises" [value]="entreprise.id">{{entreprise.nom}}</option>
            </select>
          </div>
          <div class="col-md-4">
            <select id="employeur" name="employeur" [(ngModel)]="selectedEmployeur.id" class="form-select"
                    (ngModelChange)="onEmployeurChange($event)">
              <option [value]="employeur.id" *ngFor="let employeur of employeurs">{{employeur.nom}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="inputBirthdayDate" class="form-label">Date du premier contact</label>
          </div>
          <div class="col-md-8">
            <div class="input-group">
              <input class="form-control input-date" placeholder="dd.MM.yyyy"
                     id="inputFirstContact" name="inputFirstContact" [(ngModel)]="dateHistory" ngbDatepicker
                     #dH="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary bi bi-calendar" (click)="dH.toggle()" type="button">
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="signaleE" class="form-label">Signalé par l'employeur</label>
          </div>
          <div class="col-auto">
            <input class="form-check-input" type="checkbox" id="signaleE" name="signaleE" [(ngModel)]="client.signale">

          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="signaleA" class="form-label">Signalé par d'autres</label>
          </div>
          <div class="col-md-8">
            <textarea class="form-control" id="signaleA" style="height: 100px" name="signaleA"
                      [(ngModel)]="client.signaler"></textarea>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="row">
          <div class="col-md-4">
            <label for="telpro" class="form-label">Professionnel</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="telpro" [(ngModel)]="client.telpro" name="telpro">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="email" class="form-label">Adresse e-mail</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="email" [(ngModel)]="client.email" name="email">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="etatc" class="form-label">Etat civil</label>
          </div>
          <div class="col-md-8">
            <select class="form-select" [(ngModel)]="client.etatc" name="etatc" id="etatc">
              <option [value]="etatcivils['Célibataire']">{{etatcivils[etatcivils['Célibataire']]}}</option>
              <option [value]="etatcivils['Marié-e']">{{etatcivils[etatcivils['Marié-e']]}}</option>
              <option [value]="etatcivils['Divorcé-e']">{{etatcivils[etatcivils['Divorcé-e']]}}</option>
              <option [value]="etatcivils['Séparé-e']">{{etatcivils[etatcivils['Séparé-e']]}}</option>
              <option [value]="etatcivils['Veuf/Veuve']">{{etatcivils[etatcivils['Veuf/Veuve']]}}</option>
              <option [value]="etatcivils['En couple']">{{etatcivils[etatcivils['En couple']]}}</option>
              <option [value]="etatcivils['Inconnu']">{{etatcivils[etatcivils['Inconnu']]}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="reseauf" class="form-label">Réseau familial / Autres personnes</label>
          </div>
          <div class="col-md-8">
            <textarea class="form-control" id="reseauf" style="height: 100px" name="reseauf"
                      [(ngModel)]="client.reseauf"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="assistante" class="form-label">Collaborateur/trice</label>
          </div>
          <div class="col-md-8">
            <select id="assistante" name="assistante" class="form-select" [(ngModel)]="selectedAssitante.id"
                    (ngModelChange)="onAssistanteChange($event)">
              <option [value]="assistante.id" *ngFor="let assistante of assistantesList">{{assistante.nom}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="declaration" class="form-label">Déclaration d'impôt</label>
          </div>
          <div class="col-md-8">
            <input class="form-check-input" type="checkbox" id="declaration" name="declaration"
                   [(ngModel)]="client.declaration">

          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="signaleC" class="form-label">Signalé par les collègues</label>
          </div>
          <div class="col-md-8">
            <input class="form-check-input" type="checkbox" id="signaleC" name="signaleC" [(ngModel)]="client.signalec">

          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <h3> Motifs de consultation</h3>
    <div class="col-sm-6">
      <div class="form-check">

        <label class="form-check-label" for="sociale">
          Assurances sociales
        </label>
        <input class="form-check-input" type="checkbox" id="sociale" name="sociale" [(ngModel)]="client.sociale">
      </div>
      <div class="form-check">
        <label class="form-check-label" for="contrat">
          Contrats
        </label>
        <input class="form-check-input" type="checkbox" id="contrat" name="contrat" [(ngModel)]="client.contrat">

      </div>
      <div class="form-check">

        <label class="form-check-label" for="famille">
          Famille
        </label>
        <input class="form-check-input" type="checkbox" id="famille" name="famille" [(ngModel)]="client.famille">
      </div>
      <div class="form-check">
        <label class="form-check-label" for="finance">
          Finances
        </label>
        <input class="form-check-input" type="checkbox" id="finance" name="finance" [(ngModel)]="client.finance">

      </div>
      <div class="form-check">

        <label class="form-check-label" for="impot">
          Impôts
        </label>
        <input class="form-check-input" type="checkbox" id="impot" name="impot" [(ngModel)]="client.impot">
      </div>
      <div class="form-check">
        <label class="form-check-label" for="couple">
          Matrimonial / couple
        </label>
        <input class="form-check-input" type="checkbox" id="couple" name="couple" [(ngModel)]="client.couple">

      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-check">

        <label class="form-check-label" for="penal">
          Pénal / LCR
        </label>
        <input class="form-check-input" type="checkbox" id="penal" name="penal" [(ngModel)]="client.penal">
      </div>
      <div class="form-check">
        <label class="form-check-label" for="administratif">
          Questions administratives
        </label>
        <input class="form-check-input" type="checkbox" id="administratif" name="administratif"
               [(ngModel)]="client.administratif">

      </div>
      <div class="form-check">

        <label class="form-check-label" for="retraite">
          Retraite
        </label>
        <input class="form-check-input" type="checkbox" id="retraite" name="retraite" [(ngModel)]="client.retraite">
      </div>
      <div class="form-check">
        <label class="form-check-label" for="sante">
          Santé
        </label>
        <input class="form-check-input" type="checkbox" id="sante" name="sante" [(ngModel)]="client.sante">

      </div>
      <div class="form-check">

        <label class="form-check-label" for="succession">
          Succession
        </label>
        <input class="form-check-input" type="checkbox" id="succession" name="succession"
               [(ngModel)]="client.succession">
      </div>
      <div class="form-check">
        <label class="form-check-label" for="travail">
          Travail
        </label>
        <input class="form-check-input" type="checkbox" id="travail" name="travail" [(ngModel)]="client.travail">

      </div>
    </div>
  </div>


  <div>
    <button mat-flat-button color="primary" type="submit" class="createButton" >Sauvegarder</button>
    <button mat-flat-button class="createButton" color="primary" routerLink="..">Retour</button>
  </div>

</form>
