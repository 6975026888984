<h1>Statistique {{selectedYear}}</h1>


<div class="col-md-2">
  <form [formGroup]="yearForm">
	<select class="form-select"
		formControlName="selection" (change)="onSelectionChange()">
		<option *ngFor="let year of years" [value]="year">{{
			year }}</option>
	</select>
</form>
</div>
<div class="loading-indicator" *ngIf="!isDataLoaded">
  <mat-progress-spinner class="spinner-class" mode="indeterminate"></mat-progress-spinner>
  </div>
<div *ngIf="isDataLoaded">

	<mat-tab-group>
	<mat-tab label="Global">
    <p></p>
	<h2>Statistique global {{selectedYear}}</h2>
	<div class="row">
		<div class="col-sm-6">
			<div class="row">
				<div class="col-sm-4">Nombre de dossiers actifs</div>
				<div class="col-sm-3">{{statGlobal.nombreDossierActif}}</div>
			</div>
			<div class="row">
				<div class="col-sm-4">Assurances sociales</div>
				<div class="col-sm-3">{{statGlobal.pourcentageForSocial| number : '1.2-2'}} %</div>
			</div>
			<div class="row">
				<div class="col-sm-4">Contrats</div>
				<div class="col-sm-3">{{statGlobal.pourcentageForContrat|
					number : '1.2-2'}} %</div>
			</div>
			<div class="row">
				<div class="col-sm-4">Famille</div>
				<div class="col-sm-3">{{statGlobal.pourcentageForFamille|
					number : '1.2-2'}} %</div>
			</div>
			<div class="row">
				<div class="col-sm-4">Finances</div>
				<div class="col-sm-3">{{statGlobal.pourcentageForFinance|
					number : '1.2-2'}} %</div>
			</div>
			<div class="row">
				<div class="col-sm-4">Impôts</div>
				<div class="col-sm-3">{{statGlobal.pourcentageForImpot|
					number : '1.2-2'}} %</div>
			</div>
			<div class="row">
				<div class="col-sm-4">Matrimonial / couple</div>
				<div class="col-sm-3">{{statGlobal.pourcentageForCouple|
					number : '1.2-2'}} %</div>
			</div>
			<div class="row">
				<div class="col-sm-4">Pénal / LCR</div>
				<div class="col-sm-3">{{statGlobal.pourcentageForPenal|
					number : '1.2-2'}} %</div>
			</div>
			<div class="row">
				<div class="col-sm-4">Questions administratives</div>
				<div class="col-sm-3">{{statGlobal.pourcentageForAdministratif|
					number : '1.2-2'}} %</div>
			</div>
			<div class="row">
				<div class="col-sm-4">Retraite</div>
				<div class="col-sm-3">{{statGlobal.pourcentageForRetraite|
					number : '1.2-2'}} %</div>
			</div>
			<div class="row">
				<div class="col-sm-4">Santé</div>
				<div class="col-sm-3">{{statGlobal.pourcentageForSante|
					number : '1.2-2'}} %</div>
			</div>
			<div class="row">
				<div class="col-sm-4">Succession</div>
				<div class="col-sm-3">{{statGlobal.pourcentageForSuccession|
					number : '1.2-2'}} %</div>
			</div>
			<div class="row">
				<div class="col-sm-4">Travail</div>
				<div class="col-sm-3">{{statGlobal.pourcentageForTravail|
					number : '1.2-2'}} %</div>
			</div>
      <div class="table-responsive">
        <input matInput class="form-control input-search" placeholder="Recherche" (keyup)="applyFilter($event)" #input>
        <mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Nom - Prénom </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.nom}} {{row.prenom}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="entreprise">
                <mat-header-cell *matHeaderCellDef> Entreprise </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.entreprise.nom}} </mat-cell>
            </ng-container>
          <ng-container matColumnDef="assistante">
            <mat-header-cell *matHeaderCellDef> Collaborateur/trice </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.assistante.nom}} </mat-cell>
          </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="loadClient(row.id)"></mat-row>
        </mat-table>
    </div>
		</div>
		<div class="col-sm-6">



	</div>
	</div>
	</mat-tab>
	<mat-tab label="Par entreprise">
    <p></p>
    <h2>Statistique par entreprise  {{selectedYear}}</h2>

      <div class="div-content" *ngFor="let statEntreprise of statGlobal.statsEntreprise">
          <h3>{{statEntreprise.entreprise}}</h3>

              <div class="row">
                <div class="col-sm-4">Nombre de dossiers actifs</div>
                <div class="col-sm-3">{{statEntreprise.nombreDossierActif}}</div>
              </div>
              <div class="row">
                <div class="col-sm-4">Assurances sociales</div>
                <div class="col-sm-3">{{statEntreprise.pourcentageForSocial| number : '1.2-2'}} %</div>
              </div>
              <div class="row">
                <div class="col-sm-4">Contrats</div>
                <div class="col-sm-3">{{statEntreprise.pourcentageForContrat|
                  number : '1.2-2'}} %</div>
              </div>
              <div class="row">
                <div class="col-sm-4">Famille</div>
                <div class="col-sm-3">{{statEntreprise.pourcentageForFamille|
                  number : '1.2-2'}} %</div>
              </div>
              <div class="row">
                <div class="col-sm-4">Finances</div>
                <div class="col-sm-3">{{statEntreprise.pourcentageForFinance|
                  number : '1.2-2'}} %</div>
              </div>
              <div class="row">
                <div class="col-sm-4">Impôts</div>
                <div class="col-sm-3">{{statEntreprise.pourcentageForImpot|
                  number : '1.2-2'}} %</div>
              </div>
              <div class="row">
                <div class="col-sm-4">Matrimonial / couple</div>
                <div class="col-sm-3">{{statEntreprise.pourcentageForCouple|
                  number : '1.2-2'}} %</div>
              </div>
              <div class="row">
                <div class="col-sm-4">Pénal / LCR</div>
                <div class="col-sm-3">{{statEntreprise.pourcentageForPenal|
                  number : '1.2-2'}} %</div>
              </div>
              <div class="row">
                <div class="col-sm-4">Questions administratives</div>
                <div class="col-sm-3">{{statEntreprise.pourcentageForAdministratif|
                  number : '1.2-2'}} %</div>
              </div>
              <div class="row">
                <div class="col-sm-4">Retraite</div>
                <div class="col-sm-3">{{statEntreprise.pourcentageForRetraite|
                  number : '1.2-2'}} %</div>
              </div>
              <div class="row">
                <div class="col-sm-4">Santé</div>
                <div class="col-sm-3">{{statEntreprise.pourcentageForSante|
                  number : '1.2-2'}} %</div>
              </div>
              <div class="row">
                <div class="col-sm-4">Succession</div>
                <div class="col-sm-3">{{statEntreprise.pourcentageForSuccession|
                  number : '1.2-2'}} %</div>
              </div>
              <div class="row">
                <div class="col-sm-4">Travail</div>
                <div class="col-sm-3">{{statEntreprise.pourcentageForTravail|
                  number : '1.2-2'}} %</div>
              </div>

      </div>


	</mat-tab>
    <mat-tab label="Par collaboratrice">
      <p></p>
      <h2>Statistique par collaboratrice  {{selectedYear}}</h2>

      <div class="div-content" *ngFor="let statCollaboratrice of statGlobal.statsCollaboratrice">
          <h3>{{statCollaboratrice.nomCollaboratrice}}</h3>

          <div class="row">
            <div class="col-sm-4">Nombre de dossiers actifs</div>
            <div class="col-sm-3">{{statCollaboratrice.nombreDossierActif}}</div>
          </div>
          <div class="row">
            <div class="col-sm-4">Assurances sociales</div>
            <div class="col-sm-3">{{statCollaboratrice.pourcentageForSocial| number : '1.2-2'}} %</div>
          </div>
          <div class="row">
            <div class="col-sm-4">Contrats</div>
            <div class="col-sm-3">{{statCollaboratrice.pourcentageForContrat|
              number : '1.2-2'}} %</div>
          </div>
          <div class="row">
            <div class="col-sm-4">Famille</div>
            <div class="col-sm-3">{{statCollaboratrice.pourcentageForFamille|
              number : '1.2-2'}} %</div>
          </div>
          <div class="row">
            <div class="col-sm-4">Finances</div>
            <div class="col-sm-3">{{statCollaboratrice.pourcentageForFinance|
              number : '1.2-2'}} %</div>
          </div>
          <div class="row">
            <div class="col-sm-4">Impôts</div>
            <div class="col-sm-3">{{statCollaboratrice.pourcentageForImpot|
              number : '1.2-2'}} %</div>
          </div>
          <div class="row">
            <div class="col-sm-4">Matrimonial / couple</div>
            <div class="col-sm-3">{{statCollaboratrice.pourcentageForCouple|
              number : '1.2-2'}} %</div>
          </div>
          <div class="row">
            <div class="col-sm-4">Pénal / LCR</div>
            <div class="col-sm-3">{{statCollaboratrice.pourcentageForPenal|
              number : '1.2-2'}} %</div>
          </div>
          <div class="row">
            <div class="col-sm-4">Questions administratives</div>
            <div class="col-sm-3">{{statCollaboratrice.pourcentageForAdministratif|
              number : '1.2-2'}} %</div>
          </div>
          <div class="row">
            <div class="col-sm-4">Retraite</div>
            <div class="col-sm-3">{{statCollaboratrice.pourcentageForRetraite|
              number : '1.2-2'}} %</div>
          </div>
          <div class="row">
            <div class="col-sm-4">Santé</div>
            <div class="col-sm-3">{{statCollaboratrice.pourcentageForSante|
              number : '1.2-2'}} %</div>
          </div>
          <div class="row">
            <div class="col-sm-4">Succession</div>
            <div class="col-sm-3">{{statCollaboratrice.pourcentageForSuccession|
              number : '1.2-2'}} %</div>
          </div>
          <div class="row">
            <div class="col-sm-4">Travail</div>
            <div class="col-sm-3">{{statCollaboratrice.pourcentageForTravail|
              number : '1.2-2'}} %</div>
          </div>

      </div>


    </mat-tab>
  </mat-tab-group>
</div>
