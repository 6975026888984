import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ErrorHandlerService} from './error-handler.service';
import { StatsGlobal } from '../models/stats.model';



const baseUrl = 'https://gestionnaire-server.ssie.ch:8084/stats';
//const baseUrl = 'http://localhost:8080/stats';

@Injectable({
  providedIn: 'root'
})
export class StatistiqueService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic' + btoa('apiUser:Y<{l#ZRpU7QP2,hw]h$F')
    })
  };


  constructor(private errorHandlerService: ErrorHandlerService, private http: HttpClient) {
  }

  getStatistique(year: any): Observable<StatsGlobal> {
    return this.http
      .get<StatsGlobal>(baseUrl+"/"+year, this.httpOptions);

  }
  }