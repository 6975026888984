
<form #it="ngForm" (ngSubmit)="onSubmit(it)" class="g-3" *ngIf="isDataAvailable">
  <div *ngIf="isPrincipalInfo == 'principal'">
  <h1>Modification des informations principales de {{client.nom}} {{client.prenom}}</h1>
  <div class="form-group">
    <div class="row">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-md-4">
              <label for="inputnom" class="form-label">Nom</label>
            </div>
            <div class="col-md-8">
              <input type="text" class="form-control" id="inputnom" [(ngModel)]="client.nom" name="nom">
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label for="nomjf" class="form-label">Nom de jeune fille</label>
            </div>
            <div class="col-md-8">
              <input type="text" class="form-control" id="nomjf" [(ngModel)]="client.nomjf" name="nomjf">
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label for="adresse" class="form-label">Adresse</label>
            </div>
            <div class="col-md-8">
              <textarea class="form-control form-control-textarea" id="adresse" style="height: 100px" name="adresse" [(ngModel)]="client.adresse"></textarea>
            </div>
          </div>

        </div>
        <div class="col-sm-6">
            <div class="row">
              <div class="col-md-4">
                <label for="inputprenom" class="form-label">Prénom</label>
              </div>
              <div class="col-md-8">
                <input type="text" class="form-control" id="inputprenom" [(ngModel)]="client.prenom" name="prenom">
              </div>
            </div>
          <div class="row">
            <div class="col-md-4">
              <label for="sexe" class="form-label">Sexe</label>
            </div>
            <div class="col-md-8">
              <select class="form-select" [(ngModel)]="client.sexe" name="sexe" id="sexe">
                <option [value]="sexes.Femme">{{sexes[sexes.Femme]}}</option>
                <option [value]="sexes.Homme">{{sexes[sexes.Homme]}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label for="langue" class="form-label">Langue</label>
            </div>
            <div class="col-md-8">
              <select class="form-select" [(ngModel)]="client.langue" name="langue" id="langue">
                <option [value]="langues.FR">{{langues[langues.FR]}}</option>
                <option [value]="langues.DE">{{langues[langues.DE]}}</option>
                <option [value]="langues.EN">{{langues[langues.EN]}}</option>
                <option [value]="langues.ES">{{langues[langues.ES]}}</option>
                <option [value]="langues.PT">{{langues[langues.PT]}}</option>
                <option [value]="langues.Autre">{{langues[langues.Autre]}}</option>

              </select>            </div>
          </div>

        </div>

    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-md-4">
            <label for="inputteldom" class="form-label">Domicile</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="inputteldom" [(ngModel)]="client.teldom" name="teldom">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="inputtelpro" class="form-label">Portable</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="inputtelpro" [(ngModel)]="client.telpro" name="telpro">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="inputBirthdayDate" class="form-label">Date de naissance</label>
          </div>
          <div class="col-md-8">
            <div class="input-group">
              <input class="form-control input-date" placeholder="dd.MM.yyyy"
                     id="inputBirthdayDate" name="inputBirthdayDate" [(ngModel)]="birthdayDate" ngbDatepicker #d="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary bi bi-calendar" (click)="d.toggle()" type="button">
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="inputavs" class="form-label">N° AVS</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="inputavs" [(ngModel)]="client.navs" name="navs">
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-md-4">
            <label for="telpro" class="form-label">Professionnel</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="telpro" [(ngModel)]="client.telpro" name="telpro">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="email" class="form-label">Adresse e-mail</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" id="email" [(ngModel)]="client.email" name="email">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="etatc" class="form-label">Etat civil</label>
          </div>
          <div class="col-md-8">
            <select class="form-select" [(ngModel)]="client.etatc" name="etatc" id="etatc">
              <option [value]="etatcivils['Célibataire']">{{etatcivils[etatcivils['Célibataire']]}}</option>
              <option [value]="etatcivils['Marié-e']">{{etatcivils[etatcivils['Marié-e']]}}</option>
              <option [value]="etatcivils['Divorcé-e']">{{etatcivils[etatcivils['Divorcé-e']]}}</option>
              <option [value]="etatcivils['Séparé-e']">{{etatcivils[etatcivils['Séparé-e']]}}</option>
              <option [value]="etatcivils['Veuf/Veuve']">{{etatcivils[etatcivils['Veuf/Veuve']]}}</option>
              <option [value]="etatcivils['En couple']">{{etatcivils[etatcivils['En couple']]}}</option>
              <option [value]="etatcivils['Inconnu']">{{etatcivils[etatcivils['Inconnu']]}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

    <div *ngIf="isPrincipalInfo == 'autre'">
      <h1>Modification des autres informations de {{client.nom}} {{client.prenom}}</h1>
      <div class="form-group">
        <div class="row">
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <label for="assistante" class="form-label">Collaborateur/trice</label>
              </div>
              <div class="col-auto">
                <select id="assistante" name="assistante" class="form-select" [(ngModel)]="selectedAssitante.id" (change)="onAssistanteChange($event)">
                  <option [value]="assistante.id" *ngFor="let assistante of assistantesList">{{assistante.nom}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <label for="entreprise" class="form-label">Entreprise</label>
              </div>
              <div class="col-auto">
                <select id="entreprise" name="entreprise" class="form-select" [(ngModel)]="selectedEntreprise.id"  (ngModelChange)="onEntrepriseChange($event)">
                  <option *ngFor="let entreprise of entreprises" [value]="entreprise.id">{{entreprise.nom}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <label for="employeur" class="form-label">Personne de référence</label>
          </div>
          <div class="col-auto">
            <select id="employeur" name="employeur" [(ngModel)]="selectedEmployeur.id" class="form-select" (ngModelChange)="onEmployeurChange($event)">
              <option [value]="employeur.id" *ngFor="let employeur of employeurs">{{employeur.nom}}</option>
            </select>
          </div>
        </div>
        <div class="row">

          <div class="col-auto">
            <div class="row">
              <div class="col-md-6">
                <label for="signaleC" class="form-label">Signalé par les collègues</label>
              </div>
              <div class="col-md-6">
                <input class="form-check-input" type="checkbox" id="signaleC" name="signaleC" [(ngModel)]="client.signalec">

              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-md-6">
                <label for="signaleE" class="form-label">Signalé par l'employeur</label>
              </div>
              <div class="col-md-6">
                <input class="form-check-input" type="checkbox" id="signaleE" name="signaleE" [(ngModel)]="client.signale">

              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <label for="signaleA" class="form-label">Signalé par d'autres</label>
              </div>
              <div class="col-auto">
                <textarea class="form-control" id="signaleA" style="height: 100px" name="signaleA" [(ngModel)]="client.signaler"></textarea>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-md-6">
                <label for="declaration" class="form-label">Déclaration d'impôt</label>
              </div>
              <div class="col-md-6">
                <input class="form-check-input" type="checkbox" id="declaration" name="declaration"
                       [(ngModel)]="client.declaration">

              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <label for="signaleA" class="form-label">Réseau</label>
              </div>
              <div class="col-auto">
                <textarea class="form-control" id="reseau" style="height: 100px" name="signaleA" [(ngModel)]="client.reseauf"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <label for="etatDossier" class="form-label">Etat du dossier</label>
              </div>
              <div class="col-auto">
                <select class="form-select" [(ngModel)]="client.etatDossier" name="etatDossier" id="etatDossier" (change)="onEtatChange($event)">
                  <option [value]="actifs.ACTIF">{{actifs[actifs.ACTIF]}}</option>
                  <option [value]="actifs['NON ACTIF']">{{actifs[actifs["NON ACTIF"]]}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-auto" >
            <div class="row" *ngIf="needHistorique">
              <div class="col-auto">
                <label for="dateHistory" class="form-label">Date d'exécution</label>
              </div>
              <div class="col-auto">
                <div class="input-group">
                  <input class="form-control" placeholder="dd.MM.yyyy"
                         id="dateHistory" name="dateHistory" [(ngModel)]="dateHistory" ngbDatepicker #d="ngbDatepicker">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary bi bi-calendar" (click)="d.toggle()" type="button">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
        <h2> Motifs de consultation</h2>

          <div class="form-check">

            <label class="form-check-label" for="sociale">
              Assurances sociales
            </label>
            <input class="form-check-input" type="checkbox" id="sociale" name="sociale" [(ngModel)]="client.sociale">
          </div>
          <div class="form-check">
            <label class="form-check-label" for="contrat">
            Contrats
            </label>
            <input class="form-check-input" type="checkbox" id="contrat" name="contrat" [(ngModel)]="client.contrat" >

          </div>
          <div class="form-check">

            <label class="form-check-label" for="famille">
              Famille
            </label>
            <input class="form-check-input" type="checkbox" id="famille" name="famille" [(ngModel)]="client.famille">
          </div>
          <div class="form-check">
            <label class="form-check-label" for="finance">
              Finances
            </label>
            <input class="form-check-input" type="checkbox" id="finance" name="finance" [(ngModel)]="client.finance" >

          </div>     <div class="form-check">

          <label class="form-check-label" for="impot">
            Impôts
          </label>
          <input class="form-check-input" type="checkbox" id="impot" name="impot" [(ngModel)]="client.impot">
        </div>
          <div class="form-check">
            <label class="form-check-label" for="couple">
              Matrimonial / couple
            </label>
            <input class="form-check-input" type="checkbox" id="couple" name="couple" [(ngModel)]="client.couple" >

          </div>
          <div class="form-check">

          <label class="form-check-label" for="penal">
            Pénal / LCR
          </label>
          <input class="form-check-input" type="checkbox" id="penal" name="penal" [(ngModel)]="client.penal">
        </div>
          <div class="form-check">
            <label class="form-check-label" for="administratif">
              Questions administratives
            </label>
            <input class="form-check-input" type="checkbox" id="administratif" name="administratif" [(ngModel)]="client.administratif" >

          </div>
          <div class="form-check">

          <label class="form-check-label" for="retraite">
            Retraite
          </label>
          <input class="form-check-input" type="checkbox" id="retraite" name="retraite" [(ngModel)]="client.retraite">
        </div>
          <div class="form-check">
            <label class="form-check-label" for="sante">
              Santé
            </label>
            <input class="form-check-input" type="checkbox" id="sante" name="sante" [(ngModel)]="client.sante" >

          </div>
          <div class="form-check">

          <label class="form-check-label" for="succession">
            Succession
          </label>
          <input class="form-check-input" type="checkbox" id="succession" name="succession" [(ngModel)]="client.succession">
        </div>
          <div class="form-check">
            <label class="form-check-label" for="travail">
              Travail
            </label>
            <input class="form-check-input" type="checkbox" id="travail" name="travail" [(ngModel)]="client.travail" >

          </div>
        </div>

        </div>
      </div>
    </div>
  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Hi there!</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
    </div>
    <div class="modal-body">
      <p>{{sauvegardeStatus}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Ok</button>
    </div>
  </ng-template>
    <div>
      <button mat-flat-button color="primary" type="submit" class="createButton" >Sauvegarder</button>
      <button mat-flat-button class="createButton" color="primary" routerLink="..">Retour</button>
    </div>

</form>
<!--div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Status de la sauvegarde</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{sauvegardeStatus}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div-->
<div class="loading-indicator" *ngIf="isLoadingResults">
  <mat-progress-spinner class="spinner-class" mode="indeterminate"></mat-progress-spinner>
</div>

