import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, AfterContentChecked, AfterViewChecked } from '@angular/core';
import { StatsGlobal } from '../models/stats.model';
import { StatistiqueService } from '../services/statistique.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { error } from 'console';
import { MatTableDataSource } from '@angular/material/table';
import { Clients } from '../models/clients.model';
import { Router } from '@angular/router';



@Component({
  selector: 'app-statistique',
  templateUrl: './statistique.component.html',
  styleUrls: ['./statistique.component.css']
})
export class StatistiqueComponent implements OnInit {

	statGlobal: StatsGlobal;
	selectedYear: number;
	years: number[];
	isDataLoaded: boolean = false;
  yearForm: FormGroup;
  dataSource: MatTableDataSource<Clients>;
  displayedColumns: string[] = [ 'name', 'entreprise', 'assistante'];

  private isViewChecked: boolean = false;
	// pieChartLabels: Label[] = ['Assurances sociales','Contrats','Famille', 'Impôts','Matrimonial / Couple', 'Pénal / LCR','Questions administratives','Retraite','Santé','Succession','Travail'];



  constructor(private fb: FormBuilder,private statsService: StatistiqueService, private router: Router) {

  }

  loadClient(id: number): void{
    this.router.navigate(['/client/' + id]);
  }

  ngOnInit(): void {

    this.years= this.getYears();
	 this.yearForm= this.fb.group({
    selection: [this.years[0]]
   });
   this.onSelectionChange();
  }
  onSelectionChange() {
    this.selectedYear = this.yearForm.get('selection').value;
    this.isDataLoaded=false;
    this.statsService.getStatistique(this.selectedYear).subscribe(
      response =>{
        this.statGlobal=response;
        this.isDataLoaded=true;
        this.dataSource = new MatTableDataSource(this.statGlobal.clientsDossierActif);
      },
      error =>{
        console.error('Erreur de chargement des données: ',error);
        this.isDataLoaded=true;
      }
    )
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    /*if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }*/
  }
 
  getCurrentYear() {
    const date =  new Date();
    return date.getFullYear();
}
getYears() {
    const years = [];
    const currentYear = this.getCurrentYear();
    for (let index = currentYear; index >= currentYear-10; index--) {
        years.push(index);
    }
	this.selectedYear=currentYear;
    return years;
}
}
