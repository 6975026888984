// File generated by Telosys Tools Generator ( version 3.3.0 ) - Date 2022-03-02 ( Time 15:04:23 )

import {Clients} from './clients.model';
import {ClientLightModel} from './client-light.model';

export class Journal {
	id: number; // Wrapper Type : Number
	description: string; // Wrapper Type : String
	type: number; // Wrapper Type : Number
	date: string; // Wrapper Type : Date
  clientId: Clients; // Wrapper Type : Number
}
