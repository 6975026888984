import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import {MatGridListModule} from '@angular/material/grid-list';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './client/home/home.component';
import { ProfileComponent } from './profile/profile.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

// tslint:disable-next-line:max-line-length
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { FailedComponent } from './failed/failed.component';

import { MatTableModule } from '@angular/material/table';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ClientDetailComponent } from './client/client-detail/client-detail.component';
import {ClientEditComponent} from './client/client-edit/client-edit.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateFRParserFormatter} from './utils/ngb-date-fr-parser-formateur';
import { ClientNewComponent } from './client-new/client-new.component';
import {MatSortModule} from '@angular/material/sort';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatFormFieldModule} from '@angular/material/form-field';
import { StatistiqueComponent } from './statistique/statistique.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { MatTreeModule } from '@angular/material/tree';

registerLocaleData(localeFr);
// tslint:disable-next-line:max-line-length
const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

// tslint:disable-next-line:typedef
export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
       //clientId: 'bd7c00da-9281-475c-b8fd-dea88d1cd5cb', // TEST enviroment. Uncomment to use.
      clientId: '2ded83e4-b9c2-4229-91e3-4cfcb39ccae0', // PROD testing environment
     authority: 'https://login.microsoftonline.com/214cfd0c-a902-48a9-8502-8b20fde053dd', // Prod environment. Uncomment to use.
      //authority: 'https://login.microsoftonline.com/0c423e45-b0c4-4935-9e1b-5a9e0a3d2d8c',
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/', ['user.read']);
  // tslint:disable-next-line:max-line-length
  protectedResourceMap.set('https://graph.microsoft.com/.default', ['Files.ReadWrite.All', 'Sites.Read.All']); // Prod environment. Uncomment to use.


  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read', 'Files.ReadWrite.All', 'Sites.Read.All']
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    FailedComponent,
    ClientDetailComponent,
    ClientEditComponent,
    ClientNewComponent,
    StatistiqueComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatToolbarModule,
    MatTableModule,
    MatCardModule,
    MatMenuModule,
    MatTabsModule,
    HttpClientModule,
    MsalModule,
    HttpClientModule,
    MatGridListModule,
    MatIconModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatTreeModule
  ],
  exports:[ MatSortModule ],
  providers: [
    {provide: LOCALE_ID, useValue: 'fr-FR' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},
    DatePipe

  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

