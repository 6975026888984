import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Observable, of } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { getJSDocImplementsTags } from 'typescript';
import {MatTableDataSource} from '@angular/material/table';
import { Clients } from '../../models/clients.model';
import { ClientService } from '../../services/client.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EntrepriseService } from '../../services/entreprise.service';
import { Entreprises } from '../../models/entreprises.model';
import {Router} from '@angular/router';
import moment from 'moment';
import {ActifNonActif} from '../../enum/actif.enum';
import sortingDataAccessor from '../../utils/sortingDataAccessor';

const baseUrl = 'http://localhost:8080/clients';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})


export class HomeComponent implements AfterViewInit {
  loginDisplay = false;
  clients: Clients[] =  [];

  clientEntrepriseUsers: Array<Clients> = [];
  dateNoInfo = moment('1900-01-01', 'yyyy-MM-dd');
  displayedColumns: string[] = [ 'name', 'entreprise', 'assistante', 'etatDossier'];
  actif = ActifNonActif;

  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  dataSource: MatTableDataSource<Clients>;
  isLoadingResults = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // tslint:disable-next-line:max-line-length
  constructor(private authService: MsalService, private msalBroadcastService: MsalBroadcastService, private clientService: ClientService, private http: HttpClient, private entrepriseService: EntrepriseService, private router: Router) {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
    this.isLoadingResults =true;
    this.getAllClients();
    this.dataSource = new MatTableDataSource(this.clients);
   // this.dataSource.filterPredicate = (data: Clients, filter: string) => (data.prenom.trim().toLowerCase().indexOf(filter.trim().toLowerCase()) == -1 || data.nom.trim().toLowerCase().indexOf(filter.trim().toLowerCase()) == -1 || data.assistante.nom.trim().toLowerCase().indexOf(filter.trim().toLowerCase()) == -1 || data.entreprise.nom.trim().toLowerCase().indexOf(filter.trim().toLowerCase()) == -1);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    /*if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }*/
  }



  // tslint:disable-next-line:typedef
  getAllClients() {
    // tslint:disable-next-line:triple-equals
    if (this.loginDisplay == true){
     this.clientService.getAll().subscribe(
       value => {
        console.log(value);
        this.clients = value;
        this.dataSource.data = this.clients;
        this.isLoadingResults = false;

       }
     );

  }
}

  // tslint:disable-next-line:typedef
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  loadClient(id: number): void{
    this.router.navigate(['/client/' + id]);
  }


  goToNewClient() {
    this.router.navigate(['/client-new']);
  }
}


