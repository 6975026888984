import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Clients} from '../models/clients.model';
import {ErrorHandlerService} from './error-handler.service';
import {Assistante} from '../models/users.model';
import {Entreprises} from '../models/entreprises.model';
import { ObjectDynamic } from '../models/ObjectDynamic';


const baseUrl = 'https://gestionnaire-server.ssie.ch:8084/clients';
const assistanteUrl = 'https://gestionnaire-server.ssie.ch:8084/assistantes';
const historyUrl = 'https://gestionnaire-server.ssie.ch:8084/historique';
const entrepriseUrl = 'https://gestionnaire-server.ssie.ch:8084/entreprises';
/*const baseUrl = 'http://localhost:8080/clients';
const assistanteUrl = 'http://localhost:8080/assistantes';
const historyUrl = 'http://localhost:8080/historique';
const entrepriseUrl = 'http://localhost:8080/entreprises';*/


@Injectable({
  providedIn: 'root'
})
export class ClientService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic' + btoa('apiUser:Y<{l#ZRpU7QP2,hw]h$F')
    })
  };


  constructor(private errorHandlerService: ErrorHandlerService, private http: HttpClient) {
  }

  getAll(): Observable<Clients []> {
    return this.http
      .get<Clients[]>(baseUrl, {responseType: 'json'});

  }

  get(id: any): Observable<Clients> {
    return this.http.get<Clients>(`${baseUrl}/${id}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(baseUrl, data, this.httpOptions);
  }

  saveJournal(data: any): Observable<any> {
    return this.http.post(`${baseUrl}/journal/`, data, this.httpOptions);
  }

  saveMariage(data: any): Observable<any> {
    return this.http.post(`${baseUrl}/mariage/`, data, this.httpOptions);
  }
  saveEnfant(data: any): Observable<any> {
    return this.http.post(`${baseUrl}/enfant/`, data, this.httpOptions);
  }


  createHistorique(data: any): Observable<any> {
    return this.http.post(historyUrl, data, this.httpOptions);
  }

  update(id: any, data: any): Observable<any> {
    return this.http.put(`${baseUrl}/${id}`, data);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/${id}`, this.httpOptions);
  }

  getAssistances(): Observable<Assistante []> {
    return this.http.get<Assistante []>(assistanteUrl);
  }

  getEntreprises(): Observable<Entreprises []> {
    return this.http.get<Entreprises []>(entrepriseUrl);
  }

  deleteJournal(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/journal/${id}`, this.httpOptions);
  }

  deleteMariage(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/mariage/${id}`, this.httpOptions);
  }
  deleteEnfant(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/enfant/${id}`, this.httpOptions);
  }

  getFilesMicrosoft(url:string, httpOptions: { headers: HttpHeaders }): any{
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", url, false); // false for synchronous request

    // Extract headers from HttpHeaders
    httpOptions.headers.keys().forEach(key => {
      const value = httpOptions.headers.get(key);
      if (value) {
        xmlHttp.setRequestHeader(key, value);
      }
    });

    xmlHttp.send(null);

    if (xmlHttp.status === 200) {
      return JSON.parse(xmlHttp.responseText).value;
    } else {
      throw new Error(`Error ${xmlHttp.status}: ${xmlHttp.statusText}`);
    }
  }
    
  }

