import { Component, OnInit } from '@angular/core';
import { Clients } from '../../models/clients.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../../services/client.service';
import { Sexe } from '../../enum/sexe.enum';
import { EtatCivil } from '../../enum/etatcivil.enum';
import moment from 'moment';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { ObjectDynamic } from '../../models/ObjectDynamic';
import { ObjectOffice } from '../../models/ObjectOffice';
import { MatTableDataSource } from '@angular/material/table';
import { BooleanField } from '../../enum/booleanField.enum';
import { ActifNonActif } from '../../enum/actif.enum';
import { DivorceEnum } from '../../enum/divorce.enum';
import { Historique } from '../../models/historique.model';
import { Enfants } from '../../models/enfants.model';
import { Journal } from '../../models/journal.model';
import { NgbDate, NgbDateStruct, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TypeSuivi } from '../../enum/typesuivi.enum';
import { NgbDateFRParserFormatter } from '../../utils/ngb-date-fr-parser-formateur';
import { ClientLightModel } from '../../models/client-light.model';
import { Mariages } from '../../models/mariages.model';
import { LangueEnum } from '../../enum/langue.enum';
import { NodeFile } from 'src/app/models/node.model';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  webUrl: string,
  level: number;
}


@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.css'],


})



export class ClientDetailComponent implements OnInit {

  addJournal = false;
  client: Clients;
  site: ObjectOffice;
  drive: ObjectOffice[];
  clientFolders: ObjectOffice[];
  displayedColumns: string[] = ['year', 'name'];
  displayedColumnsHistorique: string[] = ['date', 'action'];
  isDataAvailable = false;
  filesCustomer: ObjectOffice[] = [];
  filesYear: NodeFile[] = [];
  filesSubcontent: NodeFile[] = [];
  yearsContent: ObjectOffice[] = [];
  subcontentsFolder: ObjectOffice[] = [];
  yearFolderContents: ObjectOffice[] = [];
  sexes = Sexe;
  etatCivils = EtatCivil;
  langues = LangueEnum;

  typesSuivi = TypeSuivi;
  booleans = BooleanField;
  divorceEnum = DivorceEnum;
  actif = ActifNonActif;
  // tslint:disable-next-line:typedef
  dateSuivi: NgbDateStruct;
  dateDebutMariage: NgbDateStruct;
  dateFinMariage: NgbDateStruct;
  dateNaissanceE: NgbDateStruct;
  newJournal: Journal;
  newM = false;
  dateNoInfo = moment('1900-01-01', 'yyyy-MM-dd');
  private _transformer = (node: NodeFile, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      webUrl: node.webUrl,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  dataSourceHistorique: MatTableDataSource<Historique> = new MatTableDataSource<Historique>();
  dateFormatter: NgbDateFRParserFormatter;
  editionMariage = false;
  editionEnfant = false;
  newMariage: Mariages;
  newEnfant: Enfants;
  motifs: string[] = [];
  fileBaseUrl: string = "https://graph.microsoft.com/v1.0/sites";
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  newJ = false;
  // tslint:disable-next-line:max-line-length
  constructor(config: NgbModalConfig, private modalService: NgbModal, private authService: MsalService, private msalBroadcastService: MsalBroadcastService, private http: HttpClient, private actRoute: ActivatedRoute, private clientService: ClientService, private router: Router) {
    this.dateFormatter = new NgbDateFRParserFormatter();

  }

  ngOnInit(): void {
    this.loadClient(this.actRoute.snapshot.paramMap.get('id'));


  }

  // tslint:disable-next-line:typedef
  loadClientFile() {
    const yearsNodes: NodeFile[] = [];
    const subFolderNodes: NodeFile[] = [];
    const contents: NodeFile[] = [];

    const requestObj = {
      scopes: ['https://graph.microsoft.com/.default']
    };


    this.authService.acquireTokenSilent(requestObj).toPromise().then((tokenResponse: AuthenticationResult) => {
      console.log(tokenResponse.accessToken);
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'OData-MaxVersion': '4.0',
          'OData-Version': '4.0',
          Authorization: 'Bearer ' + tokenResponse.accessToken
        })
      };

      console.info(tokenResponse.accessToken);
      this.site = this.clientService.getFilesMicrosoft('https://graph.microsoft.com/v1.0/sites?search=GESTIONNAIRE', httpOptions);
      this.drive = this.clientService.getFilesMicrosoft(this.fileBaseUrl + '/' + this.site[0].id + '/drive/root/children', httpOptions);
      this.drive.forEach(entrepriseFolder => {
        if (entrepriseFolder.name.startsWith('' + this.client.entreprise.id + '-')) {
          this.clientFolders = this.clientService.getFilesMicrosoft(this.fileBaseUrl + '/' + this.site[0].id  + '/drive/root:/' + entrepriseFolder.name + '/' + this.client.id + '-' + this.client.nom + ' ' + this.client.prenom + ':/children', httpOptions);
          this.clientFolders.forEach(clientFolder => {
            let nodeYear = new NodeFile();
            nodeYear.name = clientFolder.name;
            if(clientFolder.folder){
            this.yearsContent = this.clientService.getFilesMicrosoft(this.fileBaseUrl + '/' + this.site[0].id  + '/drive/root:/' + entrepriseFolder.name + '/' + this.client.id + '-' + this.client.nom + ' ' + this.client.prenom + '/' + clientFolder.name + ':/children', httpOptions);
            this.yearsContent.forEach(yearContent => {
              let nodeFolder = new NodeFile();
              nodeFolder.name = yearContent.name;
        
              if (yearContent.folder) {
                this.subcontentsFolder = this.clientService.getFilesMicrosoft(this.fileBaseUrl + '/' + this.site[0].id  + '/drive/root:/' + entrepriseFolder.name + '/' + this.client.id + '-' + this.client.nom + ' ' + this.client.prenom + '/' + clientFolder.name + '/' + yearContent.name + ':/children', httpOptions);
                this.subcontentsFolder.forEach(file => {
                  let nodeFile: NodeFile;
                  nodeFile = new NodeFile();
                  nodeFile.name = file.name;
                  nodeFile.webUrl = file.webUrl;
                  nodeFolder.children.push(nodeFile);
                })
              
              }
              nodeYear.children.push(nodeFolder);
            })
            yearsNodes.push(nodeYear);
          }
          })
        }
      })
      this.dataSource.data = yearsNodes;
      this.isDataAvailable=true;




    });


  }
  // tslint:disable-next-line:typedef
  loadClient(id: string) {
    this.clientService.get(id).subscribe(
      value => {
        this.client = value;
        if (this.client.sociale == 1) {
          this.motifs.push('Assurances sociales');
        }
        if (this.client.contrat == 1) {
          this.motifs.push('Contrats');
        }
        if (this.client.famille == 1) {
          this.motifs.push('Famille');
        }
        if (this.client.finance == 1) {
          this.motifs.push('Finances');
        }
        if (this.client.impot == 1) {
          this.motifs.push('Impôts');
        }
        if (this.client.couple == 1) {
          this.motifs.push('Matrimonial / couple');
        }
        if (this.client.penal == 1) {
          this.motifs.push('Pénal / LCR');
        }
        if (this.client.administratif == 1) {
          this.motifs.push('Questions administratives');
        }
        if (this.client.retraite == 1) {
          this.motifs.push('Retraite');
        }
        if (this.client.sante == 1) {
          this.motifs.push('Santé');
        }
        if (this.client.succession == 1) {
          this.motifs.push('Succession');
        }
        if (this.client.travail == 1) {
          this.motifs.push('Travail');
        }
        this.dataSourceHistorique.data = this.client.historiqueList;
        console.log(this.client);
        console.log(this.client.signale);
        this.loadClientFile();
      }
    );
  }

  // tslint:disable-next-line:typedef



  openFile(file: NodeFile) {
    window.open(file.webUrl, '_blank');
  }



  editPrincipalInfo() {
    // this.router.navigateByUrl('/client/edit-principal-info', {state: this.client});
    this.router.navigate(['/client/' + this.client.id + '/edit', { isPrincipalInfo: 'principal' }]);
  }

  editReseau(client: Clients) {

  }

  deleteEnfant(enfant: Enfants) {
    this.clientService.deleteEnfant(enfant.id).subscribe(
      value => {
        console.log(value);
        location.reload();
      }
    );
  }

  editEnfant(enfant: Enfants) {
    this.newEnfant = enfant;
    this.dateNaissanceE = this.dateFormatter.parse(enfant.date);
    this.editionEnfant = true;
  }

  addEnfant(client: Clients) {
    this.newEnfant = new Enfants();
    this.newEnfant.clientId = client;
    this.editionEnfant = true;

  }
  saveEnfant() {
    this.newEnfant.date = this.dateFormatter.format(this.dateNaissanceE);

    console.log(this.newEnfant);
    this.clientService.saveEnfant(this.newEnfant).subscribe(
      value => {
        console.log(value);
        this.editionEnfant = false;
        location.reload();
      }
    );
  }

  deleteMariages(mariage: Mariages) {
    this.clientService.deleteMariage(mariage.id).subscribe(
      value => {
        console.log(value);
        location.reload();
      }
    );
  }
  editMariage(mariage: Mariages) {
    this.newMariage = mariage;
    this.newM = false;
    this.dateDebutMariage = this.dateFormatter.parse(mariage.dateDebut);
    this.dateFinMariage = this.dateFormatter.parse(mariage.dateFin);
    this.editionMariage = true;
  }

  addMariage(client: Clients) {
    this.newMariage = new Mariages();
    this.newMariage.clientId = client;
    this.newMariage.etat = 1;
    this.editionMariage = true;
    this.newM = true;

  }

  saveMariage() {

    this.newMariage.dateDebut = this.dateFormatter.format(this.dateDebutMariage);
    this.newMariage.dateFin = this.dateFormatter.format(this.dateFinMariage);

    console.log(this.newMariage);
    this.clientService.saveMariage(this.newMariage).subscribe(
      value => {
        console.log(value);
        this.editionMariage = false;
        location.reload();
      }
    );

  }

  deleteJournal(message: Journal) {
    this.clientService.deleteJournal(message.id).subscribe(
      value => {
        console.log(value);
        location.reload();
      }
    );
  }

  editJournal(message: Journal) {
    this.newJournal = message;
    //   this.newJournal.clientId = this.client;
    this.dateSuivi = this.dateFormatter.parse(message.date);
    this.addJournal = true;
    this.newJ = false;
  }

  createJournal() {

    this.newJournal = new Journal();
    this.newJournal.clientId = this.client;
    this.addJournal = true;
    this.newJ = true;
  }

  editAutreInfo(client: Clients) {
    this.router.navigate(['/client/' + this.client.id + '/edit', { isPrincipalInfo: 'autre' }]);
  }

  saveJournal() {


    this.newJournal.date = this.dateFormatter.format(this.dateSuivi);

    console.log(this.newJournal);
    this.clientService.saveJournal(this.newJournal).subscribe(
      value => {
        console.log(value);
        this.addJournal = false;
        location.reload();
      }
    );
  }


}


