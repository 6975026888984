// File generated by Telosys Tools Generator ( version 3.3.0 ) - Date 2022-03-02 ( Time 15:04:23 )

import { Entreprises } from "./entreprises.model";

export class Employeurs {
	id: number; // Wrapper Type : Number
	nom: string; // Wrapper Type : String
	idE: Entreprises; // Wrapper Type : Number
	tele: string; // Wrapper Type : String
	email: string; // Wrapper Type : String
	fonction: string; // Wrapper Type : String
	ag: number; // Wrapper Type : Number
	comite: number; // Wrapper Type : Number
	effectif: number; // Wrapper Type : Number
	flash: number; // Wrapper Type : Number
	permanences: number; // Wrapper Type : Number

}
