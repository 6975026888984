// File generated by Telosys Tools Generator ( version 3.3.0 ) - Date 2022-03-02 ( Time 15:04:22 )

import { Employeurs } from "./employeurs.model";
import { Enfants } from "./enfants.model";
import { Entreprises } from "./entreprises.model";
import { Files } from "./files.model";
import { Historique } from "./historique.model";
import { Journal } from "./journal.model";
import { Mariages } from "./mariages.model";
import { Assistante } from "./users.model";
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

export class Clients {
    id:             number;
    nom:            string;
    nomjf:          string;
    prenom:         string;
    sexe:           number;
    adresse:        string;
    teldom:         string;
    telpro:         string;
    telnat:         string;
    email:          string;
    langue:         string;
    date:           string;
    etatc:          number;
    navs:           string;
    reseauf:        string;
    signale:        number;
    signaler:       string;
    signalec:       number;
    declaration:    number;
    etatDossier:    number;
    sociale:        number;
    contrat:        number;
    famille:        number;
    finance:        number;
    impot:          number;
    couple:         number;
    penal:          number;
    administratif:  number;
    retraite:       number;
    sante:          number;
    succession:     number;
    travail:        number;
    assistante:     Assistante;
    employeur:      Employeurs;
    entreprise:     Entreprises;
    journalList:    Journal[];
    mariagesList:   Mariages[];
    filesList:      Files[];
    enfantsList:    Enfants[];
    historiqueList: Historique[];
}
