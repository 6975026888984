// File generated by Telosys Tools Generator ( version 3.3.0 ) - Date 2022-03-02 ( Time 15:04:23 )

import {Clients} from './clients.model';

export class Mariages {
	id: number; // Wrapper Type : Number
	nom: string; // Wrapper Type : String
	dateDebut: string; // Wrapper Type : Date
	dateFin: string; // Wrapper Type : Date
	etat: number; // Wrapper Type : Number
	clientId: Clients; // Wrapper Type : Number
}
