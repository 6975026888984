// File generated by Telosys Tools Generator ( version 3.3.0 ) - Date 2022-03-02 ( Time 15:04:23 )

import {Clients} from './clients.model';

export class Enfants {
	id: number; // Wrapper Type : Number
	nom: string; // Wrapper Type : String
	date: string; // Wrapper Type : Date
	adresse: string; // Wrapper Type : String
	clientId: Clients; // Wrapper Type : Number
}
