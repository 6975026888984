<div *ngIf="!loginDisplay">
    <p>Merci de vous connecter afin d'avoir accès au gestionnaire</p>

</div>

<div *ngIf="loginDisplay">
    <h1>Gestion des clients</h1>

    <!--div class="example-loading-shade" *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div-->

    <!--mat-form-field class="search-input">
        <mat-label class="search-input-label">Rechercher</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
    </mat-form-field-->

    <button mat-flat-button class="createButton" color="primary" (click)="goToNewClient()">Créer un nouveau client</button>
  <!--div class="example-loading-shade" *ngIf="isLoadingResults">

  </div-->

  <!--mat-form-field class="search-input"-->
    <input matInput class="form-control input-search" placeholder="Recherche" (keyup)="applyFilter($event)" #input>
  <!--/mat-form-field-->
    <div class="table-responsive">
        <mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Nom - Prénom </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.nom}} {{row.prenom}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="entreprise">
                <mat-header-cell *matHeaderCellDef> Entreprise </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.entreprise.nom}} </mat-cell>
            </ng-container>
          <ng-container matColumnDef="assistante">
            <mat-header-cell *matHeaderCellDef> Collaborateur/trice </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.assistante.nom}} </mat-cell>
          </ng-container>

            <ng-container matColumnDef="etatDossier">
                <mat-header-cell *matHeaderCellDef > Status du dossier </mat-header-cell>
                <mat-cell *matCellDef="let row" [ngClass]="{'green': actif[row.etatDossier] === 'ACTIF', 'red':actif[row.etatDossier] != 'ACTIF' }"> {{actif[row.etatDossier]}} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="loadClient(row.id)"></mat-row>

        </mat-table>
    </div>
  <div class="loading-indicator" *ngIf="isLoadingResults">
  <mat-progress-spinner class="spinner-class" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
