import {Component, OnInit} from '@angular/core';
import {Clients} from '../models/clients.model';
import {NgForm} from '@angular/forms';
import {Sexe} from '../enum/sexe.enum';
import {Assistante} from '../models/users.model';
import {Entreprises} from '../models/entreprises.model';
import {Employeurs} from '../models/employeurs.model';
import {EtatCivil} from '../enum/etatcivil.enum';
import {ActifNonActif} from '../enum/actif.enum';
import {BooleanField} from '../enum/booleanField.enum';
import {NgbCalendar, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientService} from '../services/client.service';
import {DatePipe, formatDate, Location} from '@angular/common';
import {ObjectDynamic} from '../models/ObjectDynamic';
import {AuthenticationResult} from '@azure/msal-browser';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MsalService} from '@azure/msal-angular';
import {ObjectOffice} from '../models/ObjectOffice';
import {Historique} from '../models/historique.model';
import moment from 'moment';
import {NgbDateFRParserFormatter} from '../utils/ngb-date-fr-parser-formateur';
import {LangueEnum} from '../enum/langue.enum';

@Component({
  selector: 'app-client-new',
  templateUrl: './client-new.component.html',
  styleUrls: ['./client-new.component.css']
})
export class ClientNewComponent implements OnInit {
  client: Clients = new Clients();
  assistantesList: Assistante [] = [];
  entreprises: Entreprises[] = [];
  employeurs: Employeurs[] = [];
  sexes = Sexe;
  etatcivils = EtatCivil;
  actifs = ActifNonActif;
  langues = LangueEnum;
  booleanField = BooleanField;
  clientJson: Clients;
  selectedEntreprise: Entreprises = new Entreprises();
  dateHistory: NgbDate;
  dateFormatter: NgbDateFRParserFormatter;
  historique: Historique = new Historique();
  beforeUpdateEtat: boolean;
  updated: boolean;
  needHistorique = false;
  oldCollaborateur: string;

  selectedAssitante: Assistante = new Assistante();
  selectedEmployeur: Employeurs = new Employeurs();
  isDataAvailable = false;
  site: ObjectOffice;
  drive: ObjectOffice[];
  birthdayDate: NgbDate;
  formatDate = 'dd.MM.yyyy';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient, private authService: MsalService, private router: Router, private actRoute: ActivatedRoute, private clientService: ClientService, private location: Location, private calendar: NgbCalendar, private datePipe: DatePipe) {
    this.dateHistory = calendar.getToday();
    this.dateFormatter = new NgbDateFRParserFormatter();
   // this.historique.date = this.dateFormatter.format(this.dateHistory);
  }

  ngOnInit(): void {
    this.loadAssistantes();
    this.loadEntreprises();

  }

  onSubmit(it: NgForm) {

    const requestObj = {
      scopes: ['https://graph.microsoft.com/.default']
    };

    console.log(this.client);

    this.client.date = this.dateFormatter.format(this.birthdayDate);
    this.client.etatDossier = 1;
   // this.client.date = this.datePipe.transform(this.client.date,'dd.MM.yyy');
    this.historique.date = this.dateFormatter.format(this.dateHistory);
    this.client.etatDossier = 1;
    this.clientService.create(this.client).subscribe(
      value => {
        this.client = value;
        this.historique.action = 'Ouverture du dossier';
        this.historique.clientId = this.client;
        console.log(value);
        this.clientService.createHistorique(this.historique).subscribe(
          valueH => {
            console.log(valueH);
          }
        );

        this.authService.acquireTokenSilent(requestObj).toPromise().then((tokenResponse: AuthenticationResult) => {
          console.log(tokenResponse.accessToken);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'OData-MaxVersion': '4.0',
              'OData-Version': '4.0',
              Authorization: 'Bearer ' + tokenResponse.accessToken
            })
          };
          this.http.get<ObjectDynamic[]>('https://graph.microsoft.com/v1.0/sites?search=GESTIONNAIRE', httpOptions).subscribe(
            valueS => {
              this.site = JSON.parse(JSON.stringify(valueS)).value[0];
              // console.log(this.site);
              // tslint:disable-next-line:max-line-length
              this.http.get<ObjectDynamic[]>('https://graph.microsoft.com/v1.0/sites/' + this.site.id + '/drive/root/children', httpOptions).subscribe(
                valueD => {
                  const folder = JSON.stringify({name: this.client.id + '-' + this.client.nom + ' ' + this.client.prenom, folder: {}});
                  this.drive = JSON.parse(JSON.stringify(valueD)).value;
                  const driveId = this.drive.find((drive: any) => drive.name == this.client.entreprise.id + '-' + this.client.entreprise.nom);
                  console.log(this.drive);
                  this.http.post<ObjectDynamic[]>('https://graph.microsoft.com/v1.0/sites/' + this.site.id + '/drive/items/' + driveId.id + '/children', folder, httpOptions).subscribe(
                    valueF => {
                      console.log('Folder create');
                      this.router.navigateByUrl('/client/'+this.client.id);
                    }
                  );


                }
              );
            }
          );

        });

      }
    );
  }

  private loadAssistantes() {
    this.clientService.getAssistances().subscribe(
      value => {
        this.assistantesList = value;
        console.log(this.assistantesList);
      }
    );

  }

  private loadEntreprises() {
    this.clientService.getEntreprises().subscribe(
      value => {
        this.entreprises = value;
        console.log(this.entreprises);

        this.isDataAvailable = true;
      }
    );

  }

  onEntrepriseChange(e) {
    console.log(e);
    // tslint:disable-next-line:triple-equals
    this.client.entreprise = this.entreprises.find((entreprise: any) => entreprise.id == e);
    this.employeurs = this.client.entreprise.employeursList;
  }


  onAssistanteChange(e) {

    this.client.assistante = this.assistantesList.find((assistante: any) => assistante.id == e);

  }

  onEmployeurChange(e) {
    this.client.employeur = this.employeurs.find((employeur: any) => employeur.id == e);
  }
}
