// File generated by Telosys Tools Generator ( version 3.3.0 ) - Date 2022-03-03 ( Time 13:44:03 )

import {Employeurs} from './employeurs.model';

export class Entreprises {
	id: number; // Wrapper Type : Number
	nom: string; // Wrapper Type : String
	tele: string; // Wrapper Type : String
	email: string; // Wrapper Type : String
	effectif: number; // Wrapper Type : Number
	apprentis: number; // Wrapper Type : Number
	adresse: string; // Wrapper Type : String
	adresse2: string; // Wrapper Type : String
	npa: string; // Wrapper Type : String
	lieu: string; // Wrapper Type : String
	contact: string; // Wrapper Type : String
	desactive: number; // Wrapper Type : Number
  employeursList: Employeurs [];
}
