import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Clients } from "../models/clients.model";
import { Entreprises } from "../models/entreprises.model";
import { ErrorHandlerService } from "./error-handler.service";


const baseUrl="http://localhost:3000/api/v1/entreprises";
@Injectable({
    providedIn: 'root'
})
export class EntrepriseService {

    httpOptions: { headers: HttpHeaders } = {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
      };

    
    constructor( private errorHandlerService: ErrorHandlerService, private http: HttpClient){}
    getAll(): any {
        this.http
        .get<Entreprises[]>(baseUrl,{ responseType: "json" }).subscribe(
          value => {
            return value;
          }
        )
     
      }
    get(id: any): Observable<Entreprises> {
        return this.http.get<Entreprises>(`${baseUrl}/${id}`);
      }
      create(data: any): Observable<any> {
        return this.http.post(baseUrl, data);
      }
      update(id: any, data: any): Observable<any> {
        return this.http.put(`${baseUrl}/${id}`, data);
      }
      delete(id: any): Observable<any> {
        return this.http.delete(`${baseUrl}/${id}`);
      }
}